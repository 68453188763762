<template>
  <v-menu top :offset-y="true">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text class="float-right mr-3" v-bind="attrs" v-on="on"
        >More Actions<v-icon right>mdi-menu-down</v-icon></v-btn
      >
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in items" :key="index">
        <v-list-item-title @click="item.action"
          ><v-icon left small>{{ item.icon }}</v-icon
          >{{ item.name }}</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  name: "InvoiceActionBtn",
  props: ["order_id"],
  data() {
    return {
      items: [
        {
          name: "Edit Sales Order",
          icon: "mdi-pencil",
          action: () =>
            this.$router.push({
              name: "edit-order",
              params: { order_id: this.order_id },
            }),
        },
        { name: "Download PDF", icon: "mdi-file-download" },
      ],
    };
  },
};
</script>